(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('game')
        .constant('VERSION', "2.182.13+EMOTION.front")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('petitionURL', "https://service.roche.aiwin.co/")
        .constant('contentURL', "https://roche.aiwin.co/content/")
        .constant('mediaURL', "https://roche.aiwin.co/content/")
        .constant('staticURL', "https://roche.aiwin.co/app/")
        .constant('config', {
	"mainButton": false,
	"devMode": false,
	"loglevel": "error",
	"login": {
		"type": "saml",
		"url": "https://service.roche.aiwin.co/saml/login/alias/roche?disco=true&idp=roche.com"
	},
	"GACode": "UA-86292146-26"
})
        .constant('modules', {
	"common": "../../common/"
})
        .constant('tenant', "roche")
;
})();
